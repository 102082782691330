import React from 'react';
import SearchLogo from "../images/Search.png";

function Search() {
  return (
    <div className="flex items-center bg-[#f7f7fa] rounded-xl p-2 h-16 w-64">
        <img className="px-2" src={SearchLogo} alt="search" loading="lazy" />
        <input
            className="flex-grow p-2 bg-transparent outline-none"
            placeholder="Search"
        />
    </div>
  )
}

export default Search