import React from 'react'

function FilterTab({tab, setTab}) {
  return (
<div className="hidden md:flex gap-4 py-14 px-10 items-start">
          <button
            className={`px-6 py-2 rounded-2xl font-semibold ${
              tab === "photos" ? "bg-indigo-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setTab("photos")}
          >
            Photos
          </button>
          <button
            className={`px-6 py-2 rounded-2xl font-semibold ${
              tab === "videos" ? "bg-indigo-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setTab("videos")}
          >
            Videos
          </button>
        </div>
  )
}

export default FilterTab