import React from 'react';
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { FaPhotoVideo, FaVideo } from "react-icons/fa";

function SidebarComponent({tab, setTab, collapsed, handleCollapsedChange}) {
  return (
    <div className="flex md:hidden">
        <Sidebar
          backgroundColor="#ffffff"
          toggled={collapsed}
          onBackdropClick={handleCollapsedChange}
          breakPoint="md"
        >
          <h1 className="p-8 font-bold">Media Options: </h1>
          <Menu iconShape="circle">
            <MenuItem
              icon={<FaPhotoVideo />}
              active={tab === "photos"}
              onClick={() => {
                setTab("photos");
                handleCollapsedChange();
              }}
            >
              Photos
            </MenuItem>
            <MenuItem
              icon={<FaVideo />}
              active={tab === "videos"}
              onClick={() => {
                setTab("videos");
                handleCollapsedChange();
              }}
            >
              Videos
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
  )
}

export default SidebarComponent